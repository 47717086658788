// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

interface Environment {
  production: boolean;
  sentry: boolean;
  headerColor: string;
  baseUrl: string;
  jobDashboardUrl: string;
  appBaseUrl: string;
  appApiBaseUrl: string;
  version: string;
  vapidPublicKey: string;
}

const environments = {
  build: {
    production: true,
    version: "2024.09.13.07.09",
  },
  localhost: {
    production: false,
    sentry: false,
    headerColor: "#EE7762",
    baseUrl: "http://localhost:3000",
    jobDashboardUrl: "http://localhost:3100/queues",
    appBaseUrl: "http://localhost:8100",
    appApiBaseUrl: "http://localhost:1234",
    version: "2024.09.13.07.09",
    vapidPublicKey:
      "BLCoc_kpTotDLuAjRwISVJYsXjsZPqOSCKbwn6T4kHZM9ZzA6c9GVs-_e51WYfW1U_i1BLiqf3Kq4_o4H53AhNU",
  },
  "acceptatie-portal.groothuisbouw.com": {
    production: true,
    sentry: true,
    headerColor: "#FE9004",
    baseUrl: "https://acceptatie-portal-api.groothuisbouw.com",
    jobDashboardUrl: "https://acceptatie-cella.groothuisbouw.com/queues",
    appBaseUrl: "https://accept.groothuisbouw.info",
    appApiBaseUrl: "https://accept-api.groothuisbouw.info",
    version: "2024.09.13.07.09",
    vapidPublicKey:
      "BLCoc_kpTotDLuAjRwISVJYsXjsZPqOSCKbwn6T4kHZM9ZzA6c9GVs-_e51WYfW1U_i1BLiqf3Kq4_o4H53AhNU",
  },
  "portal.groothuisbouw.com": {
    production: true,
    sentry: true,
    headerColor: "#0099CC",
    baseUrl: "https://portal-api.groothuisbouw.com",
    jobDashboardUrl: "https://cella.groothuisbouw.com/queues",
    appBaseUrl: "https://groothuisbouw.info",
    appApiBaseUrl: "https://api.groothuisbouw.info",
    version: "2024.09.13.07.09",
    vapidPublicKey:
      "BLCoc_kpTotDLuAjRwISVJYsXjsZPqOSCKbwn6T4kHZM9ZzA6c9GVs-_e51WYfW1U_i1BLiqf3Kq4_o4H53AhNU",
  },
  "portal.acceptance.groothuisbouw.services.comceptum.software": {
    production: true,
    sentry: true,
    headerColor: "#FE9004",
    baseUrl:
      "http://hunter.acceptance.groothuisbouw.services.comceptum.software",
    jobDashboardUrl: "https://acceptatie-cella.groothuisbouw.com/queues",
    appBaseUrl: "https://accept.groothuisbouw.info",
    appApiBaseUrl: "https://accept-api.groothuisbouw.info",
    version: "2024.09.13.07.09",
    vapidPublicKey:
      "BLCoc_kpTotDLuAjRwISVJYsXjsZPqOSCKbwn6T4kHZM9ZzA6c9GVs-_e51WYfW1U_i1BLiqf3Kq4_o4H53AhNU",
  },
  "portal.acceptance.groothuisbouw.services.comceptum.software.s3-website.eu-west-2.amazonaws.com":
    {
      production: true,
      sentry: true,
      headerColor: "#FE9004",
      baseUrl:
        "http://hunter.acceptance.groothuisbouw.services.comceptum.software",
      jobDashboardUrl: "https://acceptatie-cella.groothuisbouw.com/queues",
      appBaseUrl: "https://accept.groothuisbouw.info",
      appApiBaseUrl: "https://accept-api.groothuisbouw.info",
      version: "2024.09.13.07.09",
      vapidPublicKey:
        "BLCoc_kpTotDLuAjRwISVJYsXjsZPqOSCKbwn6T4kHZM9ZzA6c9GVs-_e51WYfW1U_i1BLiqf3Kq4_o4H53AhNU",
    },
  "portal.production.groothuisbouw.services.comceptum.software": {
    production: true,
    sentry: true,
    headerColor: "#0099CC",
    baseUrl:
      "http://hunter.production.groothuisbouw.services.comceptum.software",
    jobDashboardUrl: "https://cella.groothuisbouw.com/queues",
    appBaseUrl: "https://groothuisbouw.info",
    appApiBaseUrl: "https://api.groothuisbouw.info",
    version: "2024.09.13.07.09",
    vapidPublicKey:
      "BLCoc_kpTotDLuAjRwISVJYsXjsZPqOSCKbwn6T4kHZM9ZzA6c9GVs-_e51WYfW1U_i1BLiqf3Kq4_o4H53AhNU",
  },
};

export const environment: Environment =
  environments[location.hostname || "build"];

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
